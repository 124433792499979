exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agbs-js": () => import("./../../../src/pages/agbs.js" /* webpackChunkName: "component---src-pages-agbs-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-placeholder-js": () => import("./../../../src/pages/index-placeholder.js" /* webpackChunkName: "component---src-pages-index-placeholder-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */)
}

